.upload-image {
    cursor: pointer;
    margin-top: 10px;
    color: darkblue;
    text-decoration: underline;
  }
  
  .default-upload-image {
    margin-top: 100%;
    margin-left: 15%;
    cursor: pointer;
  }
  
  .uploadButton{
    padding: 5px 30px;
    border: 0;
    border-radius: 5px;
    background-color: #d1cfcf;
  }
  